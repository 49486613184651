import React from 'react';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import { styled } from '@material-ui/styles';

const MyFab = styled(Fab)({
    '@media (max-width: 1023px)': {
        bottom: '88px', 
    },
    position: 'fixed', 
    bottom: '32px', 
    right: '32px', 
    zIndex: '1299'
});

export const ProgrammeFab = (props) => {
    function toggleEditMode(){
        props.changeTab("Edit");
    }
    if(props.isAdmin){
        if (props.currentTab === "View"){
            return (
                <MyFab color="primary" onClick={toggleEditMode}>
                    <Icon>edit_icon</Icon>
                </MyFab>
            )
        } else if (props.currentTab === "Edit") {
            if(props.addNewItem){
                return (
                    <MyFab color="secondary" onClick={props.addNewItem}>
                        <Icon>add_icon</Icon>
                    </MyFab>
                )
            } 
        } 
    } 

    return null
}
