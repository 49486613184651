import React, {Component} from 'react';
import Icon from '@material-ui/core/Icon';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router';
import grey from '@material-ui/core/colors/grey';
import history from "../history";
import {
    Link
  } from 'react-router-dom';

const grey200 = grey['200'];
const notesIcon = <Icon className="material-icons">description</Icon>;
const listIcon = <Icon className="material-icons">event_note</Icon>;
const musicIcon = <Icon className="material-icons">music_note</Icon>;
const sharingIcon = <Icon className="material-icons">share</Icon>;

const styles = {
    root: {
        width: 500,
    },
};

const BottomNavStyle = {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    zIndex: '100',
    boxShadow: 'rgba(0, 0, 0, 0.298039) 0px -1px 20px, rgba(0, 0, 0, 0.219608) 0px -1px 20px'
}

const BottomNavStyleDesktop = {
    position: 'fixed',
    left: '0',
    top: '0',
    height: '100%',
    width: '200px',
    paddingTop: '56px'
}

 class BottomNav extends Component {


    constructor(props) {
        super(props);
        this.state = {
             value: 0
        };
    }

    select = (value) => {
        this.setState({value: value});
        // switch(value){
        //     case 0:
        //         history.push();
        //         break;
        //     case 1:
        //         history.push(this.props.serviceKey + "/People");
        //         break;
        //     case 2:
        //         history.push(this.props.serviceKey + "/Songlist");
        //         break;
        //     case 3:
        //         history.push(this.props.serviceKey + "/Sharing");
        //         break;
        //     default:
        //         history.push(this.props.serviceKey + "/Programme");
        //         break;
        // }

    }

     getSelectedIndex = (currentRoute) => {
         if(currentRoute.endsWith("Programme")){
             return 0;
         } else if(currentRoute.endsWith("People")){
             return 1;
         } else if(currentRoute.endsWith("Songs")){
             return 2;
         } else if(currentRoute.endsWith("Share")){
             return 3;
         }
     }

     componentDidMount() {
         this.setState({value: this.getSelectedIndex(this.props.currLocation)});
     }

     render() {

         // check if desktop required
         let TheBottomNavStyle, TheBottomNavItemStyle, TheInnerNavStyle = {};
        //  if (this.props.isDesktop){
        //      TheBottomNavStyle = BottomNavStyleDesktop;
        //      TheBottomNavItemStyle = { float: 'left', width: '200px', maxWidth: '104px', height: '80px' };
        //      TheInnerNavStyle = { backgroundColor: grey200, display: 'block', height: '100%' };
        //  } else {
             TheBottomNavStyle = BottomNavStyle;
             TheInnerNavStyle = { backgroundColor: grey200 };
        // }

         // console.log("selectedTab", this.state.selectedIndex);

         return (
             <Paper elevation={2} style={TheBottomNavStyle}>
                 <BottomNavigation showLabels value={this.state.value} style={TheInnerNavStyle}>

                        <BottomNavigationAction
                             label="Runsheet"
                             to={`./Programme`}
                             component={Link}
                             icon={listIcon}
                             style={TheBottomNavItemStyle}
                             className="BottomNavItem"
                             onClick={()=>this.select(0)}
                             />
                        <BottomNavigationAction
                            label="Notes"
                            to={`./Notes`}
                            component={Link}
                            icon={notesIcon}
                            style={TheBottomNavItemStyle}
                            className="BottomNavItem"
                            onClick={()=>this.select(1)}
                            />
                        {/* <BottomNavigationAction
                             label="Songs"
                             to={`./Songs`}
                             component={Link}
                             icon={musicIcon}
                             style={TheBottomNavItemStyle}
                             className="BottomNavItem"
                             onClick={()=>this.select(2)}
                             /> */}

                        <BottomNavigationAction
                             label="Share"
                             to={`./Share`}
                             component={Link}
                             icon={sharingIcon}
                             style={TheBottomNavItemStyle}
                             className="BottomNavItem"
                             onClick={()=>this.select(2)}
                             />

                 </BottomNavigation>

             </Paper>
         );
     }
 }

const BottomNavWithRouter = withRouter(BottomNav);

export default withStyles(styles)(BottomNav);
