import React from 'react';
import { styled } from '@material-ui/styles';
import moment from 'moment';
import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import {Grid, Paper, Typography} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

// use Paper with Styles
const PaperWrapper = styled(({ borderColor, backgroundColor, ...other }) => <Paper {...other} />)({
    position: 'relative', 
    overflow: 'auto', 
    borderLeft: '5px solid', 
    padding: '8px',
    borderLeftColor: props => props.borderColor, 
    backgroundColor: props => props.backgroundColor
});

const NotesArea = styled(({...other}) => <div {...other}/>)({
    fontSize: '14px',
    lineHeight: '16px', 
    color: 'rgb(100,100,100)', 
    padding: '4px 0', 
    whiteSpace: 'pre-line', 
    letterSpacing: '0',
});

export const ProgrammeItem = (props) => {
    if(props.currentTab === "Reorder"){
        let setBorderColor = blue['600'];
        let setBackground = '#fff';
        let minHeight = 30;

        return(
            <Grid spacing={2} container style={{ color: 'transparent' }}>
                {/* Time */}
                <Grid item xs={3} sm={2} style={{textAlign: 'right', minHeight: minHeight}}>
                        <Typography variant="button">
                            <div style={{color: setBorderColor, paddingTop: '10px', fontWeight: '700', fontSize: '20px', lineHeight: '24px', letterSpacing: '-0.5px'}}>
                                {props.itemTime ? 
                                    <span>
                                        <span>{props.itemTime.format("hh:mm")}</span>
                                        <span style={{fontSize: '10px'}}>&nbsp;{props.itemTime.format("a")}</span>
                                    </span>
                                :''} 
                            </div>
                        </Typography>
                    </Grid>
                <Grid item xs={9} sm={8}>
                    <div
                        ref={props.provided.innerRef}
                        style={props.draggableStyle}
                        {...props.provided.draggableProps}
                    >
                        <PaperWrapper {...props.provided.dragHandleProps} borderColor={setBorderColor} backgroundColor={setBackground} style={{minHeight: minHeight}}>
                            <Typography variant="body1" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor: 'handle'}} onClick={props.editItem}>
                                <Icon color="action" style={{cursor: 'grab', verticalAlign: 'middle', paddingBottom: '4px', paddingRight: '4px'}}>
                                    drag_indicator
                                </Icon>
                                <span style={{fontWeight: 600, letterSpacing: '-0.5px', lineHeight: '1.4'}}>
                                    {props.item.text}
                                </span>
                            </Typography>
                        </PaperWrapper>   
                    </div>
                </Grid>
            </Grid>
        )
    } else {
        let setBorderColor = blue['600'];
        let setBackground = '#fff';
        let minHeight = 48;
        if(props.currentTab !== "Edit"){
            if(props.item.data.duration < 10){
                minHeight = 48;
            } else if (props.item.data.duration < 30) {
                minHeight = 48 + parseInt(props.item.data.duration)*1.55;
            } else {
                minHeight = 48 + parseInt(props.item.data.duration)*1.77;
            }
        } else {
            minHeight = 70;
        }

        if(props.isToday){
            let itemTimeTemp = props.itemTime
            .set('year',moment().get('year'))
            .set('month', moment().get('month'))
            .set('date', moment().get('date'))
            if(moment().isAfter(itemTimeTemp,'minute')){
                setBackground = grey['100'];
                setBorderColor = grey['700'];
            } 
        }

        return (
                <Grid spacing={2} container style={{ color: 'transparent' }}>
                    {/* Time */}
                    <Grid item xs={3} sm={2} style={{textAlign: 'right', minHeight: minHeight}}>
                        <Typography variant="button">
                            <div style={{color: setBorderColor, paddingTop: '10px', fontWeight: '700', fontSize: '20px', lineHeight: '24px', letterSpacing: '-0.5px'}}>
                                {props.itemTime ? 
                                    <span>
                                        <span>{props.itemTime.format("hh:mm")}</span>
                                        <span style={{fontSize: '10px'}}>&nbsp;{props.itemTime.format("a")}</span>
                                    </span>
                                :''} 
                            </div>
                        </Typography>
                        <Typography variant="caption">
                            <div style={{color: 'rgb(140,140,140)'}}>
                                ({(props.item.data.duration === "") ? 0 : props.item.data.duration} min)
                                {(props.item.data.oldDuration) ? 
                                    <div style={{textDecoration: 'line-through'}}>({props.item.data.oldDuration} min)</div>
                                :''}
                            </div>
                        </Typography>
                    </Grid>
                    {/* Edit */}
                    {props.currentTab === "Edit" ?
                        <Grid item xs={props.currentTab === "Edit" ? 7 : 9} sm={8}>
                            <PaperWrapper borderColor={setBorderColor} backgroundColor={setBackground} style={{minHeight: minHeight}}>
                                <Typography variant="body1" style={{whiteSpace: 'pre-line', cursor: 'pointer'}} onClick={props.editItem}>
                                    <span style={{fontWeight: 600, letterSpacing: '-0.5px', lineHeight: '1.4'}}>
                                        {props.item.data.text}
                                    </span>
                                </Typography>
                                <NotesArea className="NotesArea" onClick={props.editItem}
                                    dangerouslySetInnerHTML={{__html: props.item.data.remarks}}
                                    style={{cursor: 'pointer'}}
                                >      
                                </NotesArea>
                            </PaperWrapper> 

                            <Button fullWidth color="secondary" variant="outlined" style={{marginTop: 16, marginBottom: 16}}
                            onClick={() => props.addNewItem(props.itemTime, props.item.data.duration, (props.item.data.orderCount + 0.1))}>
                                <Icon color="secondary">
                                    add
                                </Icon>
                            </Button>  
                        </Grid>
                    :''}
                    {props.currentTab === "View" || props.currentTab === "Ops" ?
                        <Grid item xs={9} sm={8}>    
                            <PaperWrapper borderColor={setBorderColor} backgroundColor={setBackground} style={{minHeight: minHeight}}>
                                <Typography variant="body1" style={{whiteSpace: 'pre-line'}}>
                                    <span style={{fontWeight: 600, letterSpacing: '-0.5px', lineHeight: '1.4'}}>
                                        {props.item.data.text}
                                    </span>
                                </Typography>
                                {(props.item.data.remarks !== null || props.item.data.remarks !== "" || props.item.data.remarks !== "<p></p>" || props.item.data.remarks !== "<p><br></p>") ? 
                                    <NotesArea className="NotesArea"
                                        dangerouslySetInnerHTML={{__html: props.item.data.remarks}}
                                    >      
                                </NotesArea>
                                :''}
                            </PaperWrapper>
                            {/* Ops Mode */}
                            {props.currentTab === "Ops" ? 
                            <Button fullWidth color="primary" variant="outlined" style={{marginTop: 16, marginBottom: 16}}
                                onClick={props.logTimeNow}>
                                Log Transition Time
                            </Button>
                            :''}
                        </Grid>
                    :''}

                    {/* Actions */}
                    {props.currentTab === "Edit" ? 
                    <Grid item xs={2} sm={2}>
                        <Icon color="primary" style={{marginTop: 8, cursor: 'pointer'}} onClick={props.editItem}>
                            edit
                        </Icon>
                        <br/>
                        <Icon color="disabled" onClick={props.deleteItem} style={{marginTop: 16, cursor: 'pointer'}}>
                            delete
                        </Icon>
                    </Grid>
                    :''}
                </Grid>
        )
    }
}
