import React, {Component} from 'react';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {ProgrammeItem} from './ProgrammeItem';
import cyan from '@material-ui/core/colors/cyan';
import indigo from '@material-ui/core/colors/indigo';
import blue from '@material-ui/core/colors/blue';
import { Document } from 'firestorter';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';

// Draggable
const backgroundGrey = 'transparent';
const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 0',
    boxShadow: '0 1px 4px rgba(0,0,0,.05)',
    margin: `8px 8px`,
    
    // change background colour if dragging
    background: isDragging ? indigo['100'] : 'white',
    
    // styles we need to apply on draggables
    ...draggableStyle
  });
  
const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? cyan['50'] : backgroundGrey,
    padding: '2px 0',
    width: '100%'
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

export const Reorder = observer(class Reorder extends Component {
    constructor(props){
        super(props);
        this.state = {
            programme: this.props.programme
        }
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    onDragEnd = (result) => {
        // let _self = this;
        // dropped outside the list
        
        if (!result.destination || (result.destination.index === result.source.index)) {
            return;
        }
    
        const programme = reorder(
            this.state.programme,
            result.source.index,
            result.destination.index
        );
    
        this.setState({
            programme
        });

        // update DB
        this.props.setIsLoading();
        programme.map(async (doc, index, arr) => {
            let document = new Document('runsheets/' + this.props.serviceKey + '/programme/' + doc.id);
            await document.update({orderCount: index});
            //console.log(index, doc.id);

            if(arr.length - 1 === index){
                // last item, recalculate timings
                this.props.reorder();
            }
        });
    }
    
    componentDidUpdate(prevProps, prevState){
        if(this.props.programme !== prevProps.programme){
            this.setState({programme: this.props.programme})
        }
    }

    render(){
        //console.log(this.state.programme);
        return (
            <div>
                <DragDropContext  onDragEnd={this.onDragEnd} isDragDisabled={false}>
                    <Droppable droppableId="droppable" isDropDisabled={false}>
                    {(provided, snapshot) => (
                        <div 
                        {...provided.droppableProps}
                        ref={provided.innerRef} 
                        style={getListStyle(snapshot.isDraggingOver)}
                        >
                        {this.state.programme.map((item, index, arr) => (
                            <Draggable
                            key={item.id}
                            index={index}
                            draggableId={item.id}
                            style={{backgroundColor: 'white'}}
                            >
                            {(provided, snapshot) => {
                                return (
                                    <div>
                                        <ProgrammeItem 
                                            provided={provided}
                                            draggableStyle={
                                                getItemStyle(
                                                    provided.draggableProps.style,
                                                    snapshot.isDragging
                                                )
                                            }
                                            item={item} 
                                            isToday={this.props.isToday} 
                                            itemTime={moment(item.itemTime)}
                                            currentTab={this.props.currentTab}
                                        ></ProgrammeItem>
                                    {provided.placeholder}
                                    </div>
                                )
                            }}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                    </Droppable>
                </DragDropContext>

                {/* {(this.state.isLoading) ?
                    <Dialog open={true} style={{padding: 30}}>
                        <DialogContent>
                            <CircularProgress />
                        </DialogContent>
                    </Dialog>
                :''} */}
            </div>
        )
    }
});
