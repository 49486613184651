import React, {Component} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {toJS} from 'mobx';
import PullRefresh from 'react-pullrefresh'

// UI COMPONENTS
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';

// Subcomponents
import Popup from './Popup.jsx';

// Firebase Store
import { observer } from 'mobx-react';
import  * as FirebaseStore from "../firebase/store";
import { Document } from 'firestorter';
import firebaseApp from '../firebase/store';
import indigo from '@material-ui/core/colors/indigo';

const indigo500 = indigo['500'];

const runsheets = FirebaseStore.store.runsheets;
const runsheet = FirebaseStore.store.runsheet;
const currentUser = FirebaseStore.store.currentUser;
const programme = FirebaseStore.store.programme;
const people = FirebaseStore.store.people;
const songs = FirebaseStore.store.songs;
const users = FirebaseStore.store.users;
const notes = FirebaseStore.store.notes;
const runsheetsByUser = FirebaseStore.store.runsheetsByUser;
const currentUserInRunsheet = FirebaseStore.store.currentUserInRunsheet;

// setup
var db = firebaseApp.firestore();

const Runsheets = observer(class Runsheets extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabValue: 0,
            text: "",
            currentKey: null,
            thePopup: null,
            newName: null,
            items: [],
            loading: true,
            userRole: null,
            userId: null,
            displayRunsheets: [],
            displayArchivedRunsheets: [],
            isLoadingInBackground: true
        };

    }

    contextTypes: {
        router: React.PropTypes.object
    }

    handleTabChange = (event, value) => {
        this.setState({ tabValue: value });
      };
    
    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    }

    checkIfAdmin(uid){

    }

    handleClosePopup = () => {
        this.setState({thePopup: null});
    };

    onTextChange = (e) => {
        this.setState({text: e.target.value});
    }

    // add new runsheet
    // add to runsheets collection
    // and add to users collection
    createRunsheet = async (data) => {
        // var userId = this.state.userId;
        var _self = this;

        runsheets.add(data).then(function(doc){
            var id = doc.id;
            FirebaseStore.addRunsheetToUser(id, _self.state.userId, "editor");
            _self.displayRunsheetsByUser();        
        });
    }


    confirmAddRunsheet = (item) => {
        var newName = "No Name";
        this.setState({newName: newName});
        const popup =
            <Popup
                isPopupOpen={true}
                handleClosePopup={this.handleClosePopup}
                handleSubmit={() => this.createRunsheet({
                    name: this.state.newName,
                    date: moment().format(),
                    time: moment("1000", "HHmm").format("HHmm"),
                    orderCount: 0,
                    lastUpdated: moment().format()
                }).then(this.handleClosePopup())}
                numActions={2}
                title="Add New Runsheet"
                message={""}>
                <TextField
                    fullWidth
                    autoFocus
                    variant="outlined"
                    label={"New Runsheet Name"}
                    onChange={this.changeName}
                    />
                <br/>
                <small>Please do not use any special characters in your name such as "/".</small>
            </Popup>

        this.setState({thePopup: popup});
    }

    changeName = (e) => {
        this.setState({newName:  e.target.value});
    }

    // set new name of duplicate service in a popup
    confirmDuplicateRunsheet = (thisRunsheetId, thisRunsheetData) => {
        var newName = thisRunsheetData.name + " copy";
        this.setState({newName: newName});
        const popup =
            <Popup
                isPopupOpen={true}
                handleClosePopup={this.handleClosePopup}
                handleSubmit={() => this.duplicateRunsheet(thisRunsheetId, thisRunsheetData).then(this.handleClosePopup())}
                numActions={2}
                title="Duplicate Runsheet"
                message={""}>
                <TextField autoFocus fullWidth
                    variant="outlined"
                    defaultValue={newName}
                    label={"New Runsheet Name"}
                    onChange={this.changeName}
                />
            </Popup>

        this.setState({thePopup: popup});
    }

    // creates a duplicate of the service
    duplicateRunsheet = async (thisRunsheetId, thisRunsheetData) => {
        this.setState({loading: true});

        var _self = this;
        try {
            await runsheets.add({
                name: this.state.newName,
                date: thisRunsheetData.date,
                time: thisRunsheetData.time,
                orderCount: thisRunsheetData.orderCount,
                lastUpdated: moment().format()
            }).then(function(newDocument){
                // set new paths
                programme.path = "runsheets/" + newDocument.id + "/programme";
                people.path = "runsheets/" + newDocument.id + "/people";
                users.path = "runsheets/" + newDocument.id + "/users";
                songs.path = "runsheets/" + newDocument.id + "/songs";
                notes.path = "runsheets/" + newDocument.id + "/notes/notes";

                db.collection("runsheets/" + thisRunsheetId + "/programme").get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc2) {
                        programme.add(doc2.data());
                    });
                });
                    
                db.collection("runsheets/" + thisRunsheetId + "/people").get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc2) {
                        people.add(doc2.data());
                    });
                });

                db.collection("runsheets/" + thisRunsheetId + "/users").get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc2) {
                        // console.log("doc2.data().userId",doc2.data().id);
                        FirebaseStore.addRunsheetToUser(newDocument.id, doc2.data().id, doc2.data().role);                        
                    });
                });

                db.collection("runsheets/" + thisRunsheetId + "/songs").get().then(function(querySnapshot) {
                    querySnapshot.forEach(function(doc2) {
                        songs.add(doc2.data());
                    });
                });

                db.collection("runsheets/" + thisRunsheetId + "/notes").doc("notes").get().then(function(querySnapshot) {
                    if(querySnapshot.data()){
                        notes.set(querySnapshot.data());
                    }
                });

                // make sure add to runsheetsByUser too
                FirebaseStore.addRunsheetToUser(newDocument.id, _self.state.userId, "editor");   
                _self.displayRunsheetsByUser();
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    // remove a serviceKey confirmation popup
    confirmDeleteRunsheet = (thisRunsheet, name) => {
        const popup =
            <Popup
                isPopupOpen={true}
                handleClosePopup={this.handleClosePopup}
                handleSubmit={() => this.deleteRunsheet(thisRunsheet).then(this.handleClosePopup())}
                numActions={2}
                title="Delete Runsheet"
                message={<span>Are you sure you want to delete this runsheet <strong>{name}</strong>?</span>}>
            </Popup>

        this.setState({thePopup: popup});
    }

    // delete runsheet and also from users' runsheet
    deleteRunsheet = async (thisRunsheet) => {
        this.setState({loading: true});
        // let's not delete for real
        // await FirebaseStore.deleteDoc(thisRunsheet);
        // var thisRunsheet = new Document();
        // await db.collection("users/" + this.state.userId + "/runsheets").doc(thisRunsheet.id).delete();
        thisRunsheet.update({
            category: 'deleted'
        });
        this.displayRunsheetsByUser();
    }

    // rename service in a popup
    confirmRenameRunsheet = (thisRunsheet) => {
        var newName = thisRunsheet.data.name;
        this.setState({ newName: newName });        
        const popup =
            <Popup
                isPopupOpen={true}
                handleClosePopup={this.handleClosePopup}
                handleSubmit={() => this.renameRunsheet(thisRunsheet, this.state.newName).then(this.handleClosePopup())}
                numActions={2}
                title="Rename Runsheet"
                message={""}>
                <TextField
                    fullWidth
                    defaultValue={newName}
                    autoFocus
                    variant="outlined"
                    label={"New Runsheet Name"}
                    onChange={this.changeName}
                />
                <br/>
                <small>Please do not use any special characters in your name such as "/".</small>
            </Popup>

        this.setState({thePopup: popup});
    }

    // renames runsheet's name
    renameRunsheet = async (thisRunsheet, newName) => {
        this.setState({loading: true});

        await thisRunsheet.update({
            name: newName
        });
        this.displayRunsheetsByUser();
    }

    // toggle archiving
    archiveRunsheet = async (thisRunsheet, thisRunsheetCategory) => {
        this.setState({loading: true});

        if(thisRunsheetCategory === 'archive'){
            await thisRunsheet.update({
                category: ''
            });
        } else {
            await thisRunsheet.update({
                category: 'archive'
            });
        }
        this.displayRunsheetsByUser();
    }

    loadRunsheetsFromCache = async () => {
        await currentUser.fetch();
        await currentUser.ready();
        if(currentUser.data.displayRunsheets && currentUser.data.displayArchivedRunsheets){
            this.setState({
                displayRunsheets: currentUser.data.displayRunsheets,
                displayArchivedRunsheets: currentUser.data.displayArchivedRunsheets,
            }, this.setState({loading: false}));
        }
    }

    componentDidMount = () => {
        var userId = FirebaseStore.getUserId();
        this.setState({userId: userId});
        currentUser.path = "users/" + userId;
        runsheetsByUser.path = "users/" + userId + "/runsheets";

        // try loading from cache first
        this.loadRunsheetsFromCache();
        // get latest in background
        this.displayRunsheetsByUser();
    }

    // get 2 databases: runsheets by user and all runsheets
    // filter all runsheets with runsheetsByUser's Ids
    displayRunsheetsByUser = async () => {
        var _self = this;
        _self.setState({isLoadingInBackground: true});

        var runsheets = db.collection("/runsheets");
        var runsheetsByUser2 = db.collection("users/" + FirebaseStore.getUserId() + "/runsheets");
        runsheetsByUser2.orderBy("date",'asc').orderBy("time","asc");

        _self.setState({displayRunsheets: [], displayArchivedRunsheets: []});

        let userOwnedRunsheetsIds = [];
        let userOwnedRunsheetsData;
        runsheetsByUser2.get().then( async function(querySnapshot) {
            querySnapshot.forEach((doc) => {
                userOwnedRunsheetsIds.push(doc.id);
            });

            // get all by ids
            try {
                userOwnedRunsheetsData = (await Promise.all(userOwnedRunsheetsIds.map(id => runsheets.doc(id).get())))
                    .filter(doc => doc.exists)
                    .map(doc => ({ [doc.id]: doc.data() }))
                    .reduce((acc, val) => ({ ...acc, ...val }), {});
        
            } catch (error) {
                console.log(`error`, error);
                return {};
            }
            
            // convert to object
            let userOwnedRunsheetsArray = Object.keys(userOwnedRunsheetsData).map(i => {
                let newObject = userOwnedRunsheetsData[i];
                newObject.id = i;
                return newObject;
            });

            // separate into two arrays
            let displayRunsheets = userOwnedRunsheetsArray.filter(function(doc){
                if(!doc.category || (doc.category !== 'archive' && doc.category !== 'deleted')){
                    return doc;
                }
            });
            displayRunsheets = _.sortBy(displayRunsheets, [function(o) { return o.date; }]);

            let displayArchivedRunsheets = userOwnedRunsheetsArray.filter(function(doc){
                if(doc.category && doc.category === 'archive'){
                    return doc;
                }
            });
            displayArchivedRunsheets = _.orderBy(displayArchivedRunsheets, [function(o) { return o.date; }], ['desc']);


            //console.log(displayArchivedRunsheets,"displayArchivedRunsheets");
            currentUser.update({
                displayRunsheets: displayRunsheets,
                displayArchivedRunsheets: displayArchivedRunsheets
            });
            _self.setState({displayRunsheets: displayRunsheets, 
                displayArchivedRunsheets: displayArchivedRunsheets, 
                loading: false, 
                isLoadingInBackground: false});

            sessionStorage.setItem('redirect', window.location.pathname);
        });
    }

    render() {
        var _self = this;
        let month;

        return (
            <div style={{marginBottom: '170px', marginTop: '56px'}}>
                {(this.state.isLoadingInBackground) ?
                    <LinearProgress />
                :''}

                <PullRefresh onRefresh={this.displayRunsheetsByUser}>
                    <Tabs value={this.state.tabValue} onChange={this.handleTabChange} 
                        style={{borderBottom: '1px solid #ccc'}}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        >
                        <Tab label="My Runsheets" />
                        <Tab label="Archive" />
                    </Tabs>
                </PullRefresh>
                
                {this.state.tabValue === 0 && 
                    <List>
                        {(this.state.loading === true) ?
                            <div style={{textAlign: 'center'}}>
                                <CircularProgress />
                            </div>
                        :   
                            (this.state.displayRunsheets.length === 0 && !this.state.isLoadingInBackground) ?
                                <div style={{padding: '16px', color: 'grey'}}>
                                You do not have any runsheets yet. Please create one or ask someone to share theirs with you!
                                </div>
                            :
                                this.state.displayRunsheets.map((doc, index) => {
                                    if(index === 0 || moment(doc.date).isAfter(month, 'month')){
                                        month = moment(doc.date).format("MMM YYYY");
                                    } else {
                                        month = "";
                                    }
                                    const newDoc = new Document("runsheets/" + doc.id);
                                    const newUserinRunsheet = new Document("runsheets/" + doc.id + "/users/" + currentUser.id);
                                    const item = <RunsheetItem
                                            currentUserInRunsheet={newUserinRunsheet}
                                            renameRunsheet={_self.confirmRenameRunsheet}
                                            deleteRunsheet={_self.confirmDeleteRunsheet}
                                            duplicateRunsheet={_self.confirmDuplicateRunsheet}
                                            archiveRunsheet={_self.archiveRunsheet}
                                            key={doc.id}
                                            id={doc.id}
                                            doc={newDoc}
                                            data={doc}
                                        />
                                    return (
                                        <div key={month + index}>
                                            {(month) !== "" ?
                                                <ListSubheader>{month.toUpperCase()}</ListSubheader>
                                            :''}
                                            {item}
                                        </div>
                                    ) 
                                })
                        }
                    </List>
                }
                {this.state.tabValue === 1 && 
                    <List>
                        {(this.state.loading === true) ?
                            <div style={{textAlign: 'center'}}>
                                <CircularProgress />
                            </div>
                        :   
                            
                            (this.state.displayArchivedRunsheets.length === 0) ?
                                <div style={{padding: '16px', color: 'grey'}}>
                                There are no archived runsheets.
                                </div>
                            :
                                this.state.displayArchivedRunsheets.map((doc) => {
                                    const newDoc = new Document("runsheets/" + doc.id);
                                    const newUserinRunsheet = new Document("runsheets/" + doc.id + "/users/" + currentUser.id);
                                    return (
                                        <RunsheetItem
                                            currentUserInRunsheet={newUserinRunsheet}
                                            renameRunsheet={_self.confirmRenameRunsheet}
                                            deleteRunsheet={_self.confirmDeleteRunsheet}
                                            duplicateRunsheet={_self.confirmDuplicateRunsheet}
                                            archiveRunsheet={_self.archiveRunsheet}
                                            key={doc.id}
                                            id={doc.id}
                                            doc={newDoc}
                                            data={doc}
                                        />
                                    ) 
                                })
                        }
                    </List>
                }

                {(!this.state.isLoadingInBackground) ? 
                    <div style={{textAlign: 'center', marginTop: '32px'}}>
                        <Button onClick={this.displayRunsheetsByUser}>Refresh</Button>
                    </div>
                :''}

                {this.state.thePopup} 
            
                <Fab color="primary" style={{position: 'fixed', bottom: '64px', right: '32px', zIndex: '1299'}} onClick={this.confirmAddRunsheet}>
                    <Icon>add_icon</Icon>
                </Fab>
            
            </div>
        );
    }
});

const RunsheetItem = observer(class RunsheetItem extends Component {
    constructor(props){
        super(props);
        this.state = {
            anchorEl: null
        }
    }

    handleClickRunsheet = (docId) => {
        const id = docId;
        programme.path = 'runsheets/' + id + '/programme';
        people.path = 'runsheets/' + id + '/people';
        songs.path = 'runsheets/' + id + '/songs';
        users.path = 'runsheets/' + id + '/users';
        notes.path = 'runsheets/' + id + '/notes/notes';
        runsheet.path = 'runsheets/' + id;
        currentUserInRunsheet.path = 'runsheets/' + id + '/users/' + currentUser.id;        
    }

    
    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    
    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    render(){
        const { auth, anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const doc = this.props.doc;
        const { name, date, lastUpdated } = this.props.data;
        var serviceDate = moment(date);
        var sideMenu = null;

        var isAdmin = false;
        if(this.props.currentUserInRunsheet.data.role === "editor"){
            isAdmin = true;
        }
        if (isAdmin) {
            sideMenu = 
            <div>
            <IconButton
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleMenuClick}
                color="inherit"
                >
                    <Icon color="inherit">more_vert</Icon>
                </IconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={this.handleMenuClose} >
                <MenuItem onClick={() => {
                    this.props.renameRunsheet(doc); 
                    this.handleMenuClose()}} 
                >Rename</MenuItem>
                <MenuItem onClick={() => {
                    this.props.deleteRunsheet(doc, this.props.data.name);
                    this.handleMenuClose()}} 
                >Delete</MenuItem>
                <MenuItem onClick={() => {
                    this.props.duplicateRunsheet(this.props.id, this.props.data);
                    this.handleMenuClose()}} 
                >Duplicate</MenuItem>
                <MenuItem onClick={() => {
                    this.props.archiveRunsheet(doc, this.props.data.category);
                    this.handleMenuClose()}}  
                >Archive/Unarchive</MenuItem>
            </Menu>
            </div>
        }

        return (

            <div key={doc.id} style={{ position: 'relative' }}>
                <ListItem>
                    <ListItemText
                        secondary={
                        <span>
                            Last updated {moment(lastUpdated).fromNow()}
                        </span>}
                    >
                    <span>
                        <span style={{
                            color: indigo500,
                            float: 'left',
                            paddingRight: '15px',
                            fontSize: '12px',
                            textTransform: 'uppercase',
                            textAlign: 'center',
                            marginTop: '-2px'
                        }}>
                            {serviceDate.format("ddd")}<br/>
                            <span style={{lineHeight: '25px', fontSize: '24px', fontWeight: '600'}}>{serviceDate.format("DD")}</span>
                            <br/>
                            {serviceDate.format("MMM")}
                        </span>
                        {name}
                    </span>

                    <Link to={"/services/" + doc.id + "/" + encodeURI(name) + "/Programme"} 
                        onClick={() => this.handleClickRunsheet(doc.id)}
                        style={{
                            display: 'block',
                            color: '#00',
                            position: 'absolute',
                            height: '72px',
                            top: '0',
                            left: '0',
                            right: '70px',
                            bottom: '0',
                            zIndex: '100'
                        }}>
                        </Link>
                    </ListItemText>
                    <ListItemSecondaryAction>{sideMenu}</ListItemSecondaryAction>
                </ListItem>
                <Divider />
            </div>
        );
    }
});

export default Runsheets;
