import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {
    TimePicker,
    DatePicker,
  } from '@material-ui/pickers';

export const ProgrammeDate = (props) => {
    let serviceDate = props.date ? moment(props.date) : moment();
    const [selectedDate, setSelectedDate] = React.useState(serviceDate);

    function handleDateChange(date) {
        setSelectedDate(date);

        var newTime = moment(date).format();

        props.runsheet.update({
            date: newTime
        });
        props.runsheet.update({ lastUpdated: moment().format() });
    }

    if(props.currentTab === "Edit"){
        return (
            <Grid container style={{ padding: 16 }}>
                <Grid item xs={12}>
                    <DatePicker
                    fullWidth
                    label="Event Date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    />
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container style={{ padding: 16 }}>
                <Grid item xs={3}>
                    <Typography variant="body2">
                        Event Date:
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="body2">
                        {serviceDate.format("dddd, D MMMM YYYY")}
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

export class ProgrammeStartTime extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedTime: moment(this.props.time, "HHmm")
        }

        this.onClose = this.onClose.bind(this);
        this.handleTimeChange = this.handleTimeChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.time !== this.props.time) {
            this.setState({selectedTime: moment(this.props.time, "HHmm")});
        }
        // } else {
        //     this.setState({selectedTime: moment()});
        // }
    }
    
    handleTimeChange(time) {
        this.setState({selectedTime: time});

        var newTime = moment(time).format("HHmm");
        this.props.runsheet.update({
            time: newTime
        });
        this.props.runsheet.update({ lastUpdated: moment().format() });

        //this.props.reorder(true);
    }

    onClose(){
        this.props.reorder(true);
    }

    render(){
        let props = this.props;
        let selectedTime = this.state.selectedTime;

        if(props.currentTab === "Edit"){
            return (
                <Grid container style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16  }}>
                    <Grid item xs={12}>
                        {selectedTime && selectedTime.isValid() ? 
                        <TimePicker
                        fullWidth
                        variant="outlined"
                        label="Start Time"
                        value={selectedTime}
                        onChange={this.handleTimeChange}
                        onClose={this.onClose}
                        />
                        : '' }
                    </Grid>
                </Grid>
            )
        } else {
            return (
                <Grid container style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}>
                    <Grid item xs={3}>
                        <Typography variant="body2">
                            Start Time:
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body2">
                            {selectedTime && selectedTime.isValid() ? selectedTime.format("LT") : ''}
                        </Typography>
                    </Grid>
                </Grid>
            )
        }
    }
}
