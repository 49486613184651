import React, {Component} from 'react';
import moment from 'moment';
import {ProgrammeFab} from './programme/ProgrammeFab';
import {EditNotes} from './programme/EditNotes';
import Typography from '@material-ui/core/Typography';

// UI COMPONENTS
// Firebase Store
import { observer } from 'mobx-react';
import * as FirebaseStore from "../firebase/store";
import firebaseApp from '../firebase/store';

var ReactGA = require('react-ga');

ReactGA.initialize('UA-101242277-1');
moment().format();

const runsheet = FirebaseStore.store.runsheet;
// const currentUser = FirebaseStore.store.currentUser;
const programme = FirebaseStore.store.programme;
const people = FirebaseStore.store.people;
const songs = FirebaseStore.store.songs;
const users = FirebaseStore.store.users;
const notes = FirebaseStore.store.notes;
const currentUserInRunsheet = FirebaseStore.store.currentUserInRunsheet;

var db = firebaseApp.firestore();

const People = observer(class People extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentTab: "View",
            thePopup: null,
            editMode: false,
            description: "",
            text: "",
            currentKey: null,
            items: [],
            userRole: null
        };

        this.changeTab = this.changeTab.bind(this);
        this.handleSaveNote = this.handleSaveNote.bind(this);

    }

    changeTab(event, value){
        this.setState({currentTab: value});
    }

    componentDidMount(){
        people.query = people.ref.orderBy('timestamp', 'asc').where("text", "==", "NEW_NOTE_FORMAT");

        // check if new notes format exists
        let newNoteRef = db.collection("runsheets/" + runsheet.id + "/notes").doc("notes");
        newNoteRef.get().then(function(doc){
            if (doc.exists) {
                // console.log("Document data:", doc.data());
                // don't need to do anything
            } else {
                console.log("No notes!");
                 // convert old people docs to new notes format
                let newData = "";
                db.collection("runsheets/" + runsheet.id + "/people").orderBy('timestamp', 'asc').get().then(function(oldPeopleDocs) {
                    oldPeopleDocs.forEach(function(doc) {
                        var item = doc.data();
                        newData += `<p><strong>${item.text}:</strong><br/>`;
                        newData += `${item.description}</p>`;
                    });
                    db.collection("runsheets/" + runsheet.id + "/notes").doc("notes").set({
                        text: newData  
                    });
                });
            }
        });
       
    }

    componentWillMount() {
        const id = this.props.serviceKey;
        programme.path = 'runsheets/' + id + '/programme';
        people.path = 'runsheets/' + id + '/people';
        songs.path = 'runsheets/' + id + '/songs';
        users.path = 'runsheets/' + id + '/users';
        runsheet.path = 'runsheets/' + id;
        currentUserInRunsheet.path = 'runsheets/' + id + '/users/' + FirebaseStore.getUserId();
    }

    handleSaveNote(note){
        notes.update({text: note});
        console.log(note, "note");
        this.setState({currentTab: "View"});
    }

    render() {
        // check if user is admin
        var isAdmin = false; // @TODO Change back later
        if(currentUserInRunsheet.data.role === "editor") {
            isAdmin = true;
        }

        return (
            <div style={{paddingBottom: '150px', marginTop: '56px'}}>
                <ProgrammeFab changeTab={(tabName) => this.changeTab(null,tabName)} currentTab={this.state.currentTab} isAdmin={isAdmin}></ProgrammeFab>
                
                {(this.state.currentTab === "Edit") ?
                    <EditNotes note={notes.data.text} open={true} handleSaveNote={this.handleSaveNote}></EditNotes>
                :
                    <div style={{padding: 32}}>
                        <Typography variant="h4" gutterBottom>
                            <div style={{fontWeight: 700, marginBottom: 32}}>Notes</div>
                        </Typography>
                        <div dangerouslySetInnerHTML={{__html: notes.data.text }}  className="NotesArea"/>
                        {notes.data.text === "" ?
                            <div>There are no notes added yet.</div>
                        :''}
                    </div>
                }

        </div>
        );
    }
});

export default People;
