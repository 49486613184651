import React from 'react';
import {Tabs, Tab} from '@material-ui/core';


export const ProgrammeTabs = (props) => {
    if(props.isAdmin) {
        return (
            <div>
                <Tabs style={{borderBottom: '1px solid #ccc', position: 'fixed', top: '56px', width: '100%', zIndex: '1100', backgroundColor: '#fff'}}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth" value={props.currentTab} onChange={props.changeTab}>
                    <Tab label="View" value="View">
                    </Tab>
                    
                    <Tab label="Edit" value="Edit">
                    </Tab>

                    <Tab label="Reorder" value="Reorder">
                    </Tab>
                    
                    <Tab label="Ops" value="Ops">
                    </Tab>
                
                </Tabs>
                <div style={{height: '48px', marginBottom: '16px'}}></div>
            </div>
            )
    } else {
        return null
    }
}
