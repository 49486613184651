import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

export default class Popup extends React.Component {

    render() {
        var actions = null;
        // check how many actions defined in this pop-up
        if(this.props.numActions === 2){
            actions = 
            <div>
                <Button
                    color="primary"
                    onClick={this.props.handleClosePopup}>Cancel</Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.props.handleSubmit}
                >OK</Button>
            </div>;
        } else if(this.props.numActions === 0){
            actions = null;
        } else {
            actions = <div>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={this.props.handleSubmit}
                >OK</Button>
            </div>;
        }
        return (
            <Dialog
                open={this.props.isPopupOpen}
                onClose={this.props.handleClosePopup}
                >
                <DialogTitle>
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    {this.props.message}
                    {this.props.children}
                </DialogContent>
                <DialogActions>{actions}</DialogActions>
            </Dialog>
        );
    }
}
