import React, {useState} from 'react';
import ReactQuill, {Quill} from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import AutoLinks from 'quill-auto-links';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

Quill.register('modules/autoLinks', AutoLinks);

const modules = {
    autoLinks: true,
    toolbar: [
        ['bold', 'italic', 'underline'],        // toggled buttons             // custom button values
        ['link'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //[{ 'font': [] }],                     
    ],
};

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    saveButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2)
    },
    title: {
        paddingLeft: theme.spacing(1)
    }
});

// hide bottom bar in mobile
const styles2 = {
    root: {
        display: 'none',
        '@media (min-width: 960px)': {
            display: 'flex', 
        },
    }
}

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, onSubmit } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography className={classes.title} variant="h6">{children}</Typography>
        {onSubmit ? (
          <Button size="small" variant="contained" color="primary" aria-label="Save" className={classes.saveButton} onClick={onSubmit}>
            Save
          </Button>
        ) : null}
      </MuiDialogTitle>
    );
});

const EditNotesDialog = (props) => {
    const [note, setNote] = useState(props.note);
    const [html, setHtml] = useState(props.note);

    const changeNote = (value, delta, source, editor) => {
        setNote(value);
        setHtml(editor.getHTML());
    }

    const saveNote = () => {
        props.handleSaveNote(html);
    }

    let { fullScreen } = props;

    let { open, classes } = props;

    console.log(html, "html");

    return (
        <Dialog scroll={"paper"} fullScreen={fullScreen} open={open} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" onSubmit={saveNote}>
                Edit Notes
            </DialogTitle>
            <DialogContent>
                <ReactQuill 
                    modules={modules}
                    value={note}
                    onChange={changeNote} />

                {(note === "" || note === "<p><br></p>" || note === "<p></p>") ?
                <div style={{marginTop: 32}}>
                    <strong>Notes Template:</strong>
                    <p><strong>Preacher:</strong></p><p><br/></p><p><strong>ADC:</strong></p><p><strong>Producer:</strong></p><p><strong>OPs IC:</strong></p><p><br/></p><p><strong>Service IC:</strong></p><p><strong>Holy Comm:</strong></p><p><br/></p><p><strong>Singers:</strong></p><p><br/></p><p><strong>Stage positions:</strong></p><p><br/></p><p><strong>Musicians:</strong></p> 
                </div>
                :''}
            </DialogContent>
            <DialogActions className={classes.root}>
                <Button variant="contained" onClick={saveNote} color="primary">
                Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const EditNotesMobile = withMobileDialog()(EditNotesDialog);
export const EditNotes = withStyles(styles2)(EditNotesMobile);
