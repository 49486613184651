import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import moment from 'moment';
import ReactQuill, {Quill} from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import AutoLinks from 'quill-auto-links';

import * as FirebaseStore from "../../firebase/store";
const runsheet = FirebaseStore.store.runsheet;
const programme = FirebaseStore.store.programme;

Quill.register('modules/autoLinks', AutoLinks);


const modules = {
    autoLinks: true,
    toolbar: [
        ['bold', 'italic', 'underline'],        // toggled buttons             // custom button values
        ['link'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //[{ 'font': [] }],                     
    ]
    // keyboard: [{
    //     bindings: {
    //         tab: {
    //             key: 'Tab',
    //             handler: function(range) {
    //                 return false
    //             },
    //         }
    //     }
    // }]
};

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      left: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    saveButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2)
    },
    title: {
        paddingLeft: theme.spacing(5)
    }
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, onSubmit } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography className={classes.title} variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
        {onSubmit ? (
          <Button size="small" variant="contained" color="primary" aria-label="Save" className={classes.saveButton} onClick={onSubmit}>
            Save
          </Button>
        ) : null}
      </MuiDialogTitle>
    );
});

// hide bottom bar in mobile
const styles2 = {
    root: {
        display: 'none',
        '@media (min-width: 960px)': {
            display: 'flex', 
        },
    }
}
  
class AddEditItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isAdd: this.props.isAdd,
            startTime: this.props.currentItemStartTime,
            orderCount: this.props.newOrderCount,
            duration: "",
            text: "",
            remarks: "",
            isLoading: false,
            unsavedChanges: false
        }
        
        this.initialState = this.state;

        this.editItem = this.editItem.bind(this);
        this.checkForChanges = this.checkForChanges.bind(this);
    }

    componentDidMount() {
        if(this.props.currentItem !== null){
            this.setState({
                startTime: this.props.currentItemStartTime,
                orderCount: this.props.currentItem.orderCount,
                duration: this.props.currentItem.duration,
                text: this.props.currentItem.text,
                remarks: this.props.currentItem.remarks
            }, function(){
                this.initialState = this.state
            });
        }

        // remove tabindex for toolbar
        setTimeout(function(){ 
            document.querySelectorAll(".ql-formats *").forEach(function(button){ 
                button.setAttribute('tabindex', '-1')
            });
        }, 1000);
    }

    editItem = async (doc, orderCount, duration, text, remarks) => {
        if(orderCount === undefined) orderCount = "0";
        if(duration === undefined) duration = "0";
        if(text === undefined) text = "";
        if(remarks === undefined) remarks = "";
        await doc.update({
            orderCount: orderCount,
            duration: duration,
            text: text,
            remarks: remarks
        });
    }

    handleSubmit = () => {
        this.setState({isLoading: true});
        let _self = this;

        // adding
        if(this.state.isAdd){
            FirebaseStore.addDocToCollection(programme, {
                orderCount: this.props.newOrderCount, 
                duration: this.state.duration, 
                text: this.state.text, 
                remarks: this.state.remarks
            })
            .then(function(){
                _self.setState({isLoading: false});
                runsheet.update({ lastUpdated: moment().format()});
                _self.props.reorder(true);
                _self.props.handleClose();
            });
        }
        // adding
        else {
            this.editItem(
                this.props.doc, 
                this.state.orderCount, 
                this.state.duration, 
                this.state.text, 
                this.state.remarks
            )
            .then(function(){
                _self.setState({isLoading: false});
                runsheet.update({ lastUpdated: moment().format()});
                _self.props.reorder(true);
                _self.props.handleClose();
            });
        }
    }

    checkForChanges(){
        // show popup if there are unsaved changes
        if(this.state.text !== this.initialState.text ||
            this.state.remarks !== this.initialState.remarks ||
            this.state.duration !== this.initialState.duration){
            this.setState({unsavedChanges: true});
        } else {
            this.props.handleClose();
        }
    }

    render(){
        //console.log(this.initialState);

        let { fullScreen } = this.props;
        let { open, handleClose, classes } = this.props;
        
        // calculate duration for end time
        let durationMoment = moment.duration(parseInt(this.state.duration), 'minutes');
        let endTime = null;
        if(this.state.startTime){
            endTime = this.state.startTime.clone();
            endTime = endTime.add(durationMoment);
        }

        let startEndTimeString = <span>{this.state.startTime ? this.state.startTime.format("LT") :''}
                                    &nbsp;&ndash;&nbsp;<br/> {endTime ? endTime.format("LT") :''}</span>;
        return (
            <div>
                <Dialog scroll={"paper"} fullScreen={fullScreen} open={open} onClose={this.checkForChanges} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title" onClose={this.checkForChanges} onSubmit={this.handleSubmit}>
                        {this.state.isAdd ?
                            "Add New Item"
                            : "Edit Item"
                        }
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <div style={{marginBottom: 16}}>
                                <TextField
                                    label="Title"
                                    fullWidth
                                    multiline
                                    rowsMax="5"
                                    value={this.state.text}
                                    onChange={(e) => this.setState({text: e.target.value})}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div>
                            <div style={{marginBottom: 32}}>
                                <div style={{color: 'rgba(0, 0, 0, 0.54)', padding: 0, fontSize: '0.8rem'}}>
                                    Notes (Optional)
                                </div>
                                <ReactQuill 
                                    modules={modules}
                                    value={this.state.remarks}
                                    onChange={(value, delta, source, editor) => {
                                        this.setState({remarks: editor.getHTML()});
                                    }} />
                               
                                {/* <TextField
                                    label="Notes (Optional)"
                                    fullWidth
                                    multiline
                                    rowsMax="5"
                                    value={this.state.remarks}
                                    onChange={(e) => this.setState({remarks: e.target.value})}
                                    margin="normal"
                                    variant="outlined"
                                /> */}
                            </div>
                            <Grid spacing={2} container>
                                <Grid item xs={1}>
                                    <Icon style={{marginTop: 16, textAlign: 'center'}} color="primary">
                                        timelapse
                                    </Icon>
                                </Grid>
                                <Grid item xs={11}>
                                    <TextField 
                                    fullWidth 
                                    variant="outlined" type="number" 
                                    name="Duration" id="Duration" label="Duration (mins)" value={this.state.duration} 
                                    onChange={(e) => this.setState({duration: e.target.value})}  
                                    helperText="The start and end time will be calculated automatically from the duration"
                                    />
                                </Grid>
                            </Grid>
                            <Grid spacing={2} container>
                                <Grid item xs={1}>
                                    <Icon style={{marginTop: 8, textAlign: 'center'}} color="primary">
                                        timer
                                    </Icon>
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography variant="button" color="primary">
                                        <div style={{paddingLeft: 8, marginBottom: 16, fontSize: 24}}>
                                            {startEndTimeString}
                                        </div>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.root}>
                        <Button size="small" onClick={this.checkForChanges} color="primary">
                        Cancel
                        </Button>
                        <Button variant="contained" onClick={this.handleSubmit} color="primary">
                        Save
                        </Button>
                    </DialogActions>

                    <Dialog
                        open={this.state.unsavedChanges}
                        onClose={handleClose}
                    >
                        <MuiDialogTitle id="alert-dialog-title">You may have unsaved changes</MuiDialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to save your changes?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            No
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary" variant="contained" autoFocus>
                            Yes, Save
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Dialog>
            </div>
        );
    }
}

const AddEditItemMobile = withMobileDialog()(AddEditItem);
export default withStyles(styles2)(AddEditItemMobile);
