import React, {Component} from 'react';
import moment from 'moment';
import {Snackbar, Button} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Clock from 'react-live-clock';

// my components
import {ProgrammeDate,ProgrammeStartTime} from './programme/ProgrammeDate';
import {ProgrammeFab} from './programme/ProgrammeFab';
import {ProgrammeItem} from './programme/ProgrammeItem';
import {ProgrammeTabs} from './programme/ProgrammeTabs';
import {Reorder} from './programme/Reorder';
import AddEditItem from './programme/AddEditItem';
import blue from '@material-ui/core/colors/blue';
import {LogTimeNow} from './programme/LogTimeNow';

// Firestore things
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as FirebaseStore from "../firebase/store";
import firebaseApp from "../firebase/store";
import { Document } from 'firestorter';

// setup
const runsheet = FirebaseStore.store.runsheet;
const currentUser = FirebaseStore.store.currentUser;
const programme = FirebaseStore.store.programme;
const people = FirebaseStore.store.people;
const songs = FirebaseStore.store.songs;
const users = FirebaseStore.store.users;
const notes = FirebaseStore.store.notes;
const currentUserInRunsheet = FirebaseStore.store.currentUserInRunsheet;
const db = firebaseApp.firestore();

const Programme = observer(class Programme extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDeleted: false,
            thePopup: null,
            isAdd: true,
            showAddEditItem: null,
            snackbarOpen: false,
            timingsArray: [],
            currentTab: "View",
            currentItem: null,
            currentItemStartTime: null,
            currentItemDoc: null,
            isLoading: false,
            isAdmin: false,
            showLoginPrompt: false
        }

        this.reorder = this.reorder.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.changeTab = this.changeTab.bind(this);
    }

    getUser = async () => {
        let userId = await FirebaseStore.getUserIdAsync();
        console.log(userId);
        currentUser.path = "users/" + userId;
        currentUserInRunsheet.path = 'runsheets/' + this.props.serviceKey + '/users/' + userId;
    }

    componentWillMount = async () => {
        const id = this.props.serviceKey;
        programme.path = 'runsheets/' + id + '/programme';
        people.path = 'runsheets/' + id + '/people';
        songs.path = 'runsheets/' + id + '/songs';
        users.path = 'runsheets/' + id + '/users';
        notes.path = 'runsheets/' + id + '/notes/notes';
        runsheet.path = 'runsheets/' + id;
    }

    checkUser = async () => {
        // check if user exist, if not add user to doc
        // if there is no user data yet
        let userId;
        try {
            userId = await FirebaseStore.getUserIdAsync();
        } catch (err) {
            console.log(err, "no user");
            this.setState({showLoginPrompt: true});
            sessionStorage.setItem('redirect', window.location.pathname);  
        }
        if(userId){
            console.log(userId,"userId");
            currentUser.path = "users/" + userId;
            currentUserInRunsheet.path = 'runsheets/' + this.props.serviceKey + '/users/' + userId;        
            // if user is not added to this sheet as he enter via link
            FirebaseStore.addNewUserToDoc(runsheet);

            await currentUserInRunsheet.fetch();
            if(currentUserInRunsheet.data.role === "editor") {
                this.setState({isAdmin: true});
            } 
        }
    }

    componentDidMount = async () => {
        // update time every minute
        // setInterval(this.highlightCurrentTime, 30000);
        
        // check if document exists
        await runsheet.ready();
        await programme.ready();
        if (runsheet.hasData && runsheet.data.category === "deleted") {
            sessionStorage.setItem('redirect', '/Runsheets');       
            this.setState({
                isDeleted: true
            });
        } else if(runsheet.hasData){
            // track last redirect
            sessionStorage.setItem('redirect', window.location.pathname);       
            this.reorder();
        } else {
            console.log("no data");
            sessionStorage.setItem('redirect', '/Runsheets');       
            this.setState({
                isDeleted: true
            });
        }

        // check if logged in
        this.checkUser();

        // scroll to top
        window.scrollTo(0, 0);
    }

    reorder = async (forceUpdate) => {
        programme.query = programme.ref.orderBy('orderCount', 'asc');
        await programme.ready();
        await runsheet.ready();
        if (forceUpdate || !runsheet.data.timingsUpdated){
            this.setState({isLoading: true});
            console.log("Timings Not Updated or forcing update");
            this.calculateTimings();
        } else {
            console.log("Timings Updated");
            this.setState({isLoading: false});
            // timings updated, do nothing
        } 
    }

    calculateTimings = async () => {
        let _self = this;
        await runsheet.ready();

        // cannot use firestorter as sometimes it will not be ordered!
        var tempDocs = db.collection(programme.path).orderBy('orderCount', 'asc');
        tempDocs.get().then(function(docs) {
            let previousDuration = moment.duration(0, 'minutes'); // store previous item duration
            let newTime = moment(runsheet.data.time, "HHmm"); // first time is service start time
            let timingsArrayTemp = [];
            let docCount = -1;
            console.log(docs);

            docs.forEach(async (doc) => {
                docCount++;
                newTime.add(previousDuration);
                let itemTime = newTime.clone();
                timingsArrayTemp[doc.id] = itemTime;
                previousDuration = moment.duration(parseInt(doc.data().duration), 'minutes');

                // update orderCount so that there's no missing number in between when deleted
                let document = new Document('runsheets/' + _self.props.serviceKey + '/programme/' + doc.id);
                await document.update({ orderCount: docCount, itemTime: itemTime.toISOString() }); 
            });
            _self.setState({timingsArray: timingsArrayTemp, isLoading: false});
            FirebaseStore.store.timingsArray = timingsArrayTemp;
            runsheet.update({ orderCount: docCount + 1, timingsUpdated: true }); 
        });     
    }

    changeTab(event, value){
        this.setState({currentTab: value});
    }

    addNewItem(lastItemInList, lastDurationInList, orderCount){
        let startTime = lastItemInList ? 
            lastItemInList.clone() : 
            moment(runsheet.data.time, "HHmm");

        // add duration
        if(lastItemInList){
            let durationMoment = moment.duration(parseInt(lastDurationInList), 'minutes');
            startTime.add(durationMoment);
        }
        console.log("startTime", startTime);

        // if this is a in between add
        let newOrderCount;
        if(orderCount){
            newOrderCount = orderCount;
        } else {
            newOrderCount = runsheet.data.orderCount;
        }
        console.log(newOrderCount, "neworder");
        let theAddEditItem = <AddEditItem 
            isAdd={true}
            newOrderCount={newOrderCount} // order count starts from 0, don't need to plus 1
            currentItemStartTime={startTime}
            currentItem={null} 
            doc={null}
            reorder={this.reorder} 
            open={true} 
            handleClose={() => this.setState({showAddEditItem: null})}/>

        this.setState({
            isAdd: true,
            currentItemStartTime: startTime,
            currentItem: null,
            currentItemDoc: null,
            showAddEditItem: theAddEditItem
        });
    }

    editItem = (itemData, currentItemStartTime, doc) => {
        let newOrderCount = runsheet.data.orderCount;
        let theAddEditItem = <AddEditItem 
        isAdd={false}
        newOrderCount={newOrderCount} // order count starts from 0, don't need to plus 1
        currentItemStartTime={currentItemStartTime}
        currentItem={itemData} 
        doc={doc}
        reorder={this.reorder} 
        open={true} 
        handleClose={() => this.setState({showAddEditItem: null})}/>

        this.setState({
            isAdd: false, 
            currentItem: itemData, 
            currentItemStartTime: currentItemStartTime,
            currentItemDoc: doc
        },
            this.setState({showAddEditItem: theAddEditItem}) );
    }

    deleteItem = (doc) => {
        var _self = this;
        const popup =
            <Dialog open={true}
                    onClose={()=> _self.setState({thePopup: null})}
                >
                <DialogContent>
                <div style={{marginTop: 16, marginBottom: 32}}>
                Confirm delete?
                </div>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => _self.setState({thePopup: null})} color="primary">
                    Cancel
                </Button>
                    <Button onClick={() => FirebaseStore.deleteDoc(doc).then(function () {
                    runsheet.update({ lastUpdated: moment().format() });
                    _self.reorder(true);
                    _self.setState({thePopup: null});
                })} color="primary" variant="contained" autoFocus>
                    Delete
                </Button>
                </DialogActions>
            </Dialog>

        this.setState({thePopup: popup});
    }

    logTimeNow = (doc, data) => {
        var _self = this;
        const popup = <LogTimeNow
            onClose={() => _self.setState({thePopup: null})}
            doc={doc}
            data={data}
            onSubmit={async (endTime) => {
                let now = endTime;
                let originalTime = data.itemTime;
                // diff is new duration of this block
                let newDuration = now.diff(originalTime, 'm');
                console.log(newDuration);
                // if difference is positive, now time is later than original time
                await doc.update({oldDuration: data.duration, duration: newDuration});
                await runsheet.update({ lastUpdated: moment().format() });
                _self.reorder(true);
                _self.setState({thePopup: null});
            }} />

        this.setState({thePopup: popup});
    }

    render(){
        // check if user is admin
        let isAdmin = this.state.isAdmin

        let serviceDate = runsheet.data.date ? moment(runsheet.data.date) : moment();
        let isToday = moment().isSame(serviceDate,'day');

        // for adding new items
        let lastItemInList;
        let lastDurationInList;
        let deletedMessage;

        if(this.state.isDeleted){
            deletedMessage =
                <div style={{background: 'rgb(245, 245, 245)', paddingBottom: '200px', paddingTop: '80px', textAlign: 'center'}} id="prog">
                    Oops, either the internet is not working, or this runsheet no longer exists. <br/>
                    Please get a new link from your admin. 
                </div>
        }

        return (
            <div style={{background: 'rgb(245, 245, 245)', paddingBottom: '200px', marginTop: '56px', minHeight: '100vh'}} id="prog">
                
                <ProgrammeTabs currentTab={this.state.currentTab} changeTab={this.changeTab} isAdmin={isAdmin}></ProgrammeTabs>
                
                <Typography variant="h5">
                    <div style={{fontWeight: 700, paddingLeft: 16, paddingTop: 16}}>{runsheet.data.name}</div>
                </Typography>
                
                {deletedMessage}

                <ProgrammeDate currentTab={this.state.currentTab} isAdmin={isAdmin} runsheet={runsheet} date={runsheet.data.date}></ProgrammeDate>
                <ProgrammeStartTime currentTab={this.state.currentTab} isAdmin={isAdmin} runsheet={runsheet} reorder={this.reorder} time={runsheet.data.time}></ProgrammeStartTime>
                <ProgrammeFab addNewItem={() => this.addNewItem(lastItemInList, lastDurationInList)} changeTab={(tabName) => this.changeTab(null,tabName)} currentTab={this.state.currentTab} isAdmin={isAdmin}></ProgrammeFab>
                

                <div style={{padding: '8px 16px', textAlign: 'center'}}>
                    {this.state.currentTab === "Edit" ? 
                        programme.docs.length === 0 ? // empty state
                            <div>
                                <Divider style={{marginBottom: '16px'}} />
                                <small>Enter a start time and add items to this runsheet. 
                                All subsequent item timings will be set based on the start time and the item's duration.</small>
                            </div>
                        :
                            <div>
                                <small>If the timings are incorrectly shown &nbsp;</small><br/>
                                <Button color="primary" variant="outlined" onClick={() => this.reorder(true)} style={{marginTop: 8}}>Re-calculate Timings</Button>
                            </div>
                        : ''
                    }
                    {this.state.currentTab === "Ops" ? 
                            <div>
                                <Divider style={{marginBottom: '16px'}} />
                                <small>
                                Ops Mode: During the event, press "Log Transition Time" after each item to set current real time for the next item. 
                                This will automatically push back all other items below it to calculate a new end time.
                                </small>
                            </div>
                    :''}
                </div>

                <div style={{padding: '8px 16px'}}>
                    
                {this.state.currentTab === "Reorder" ? 
                    <Reorder programme={programme.docs.map((doc) => {
                        return {
                            id: doc.id,
                            ...toJS(doc.data)
                        }
                    })} 
                    isToday={isToday} 
                    currentTab={this.state.currentTab} 
                    serviceKey={this.props.serviceKey} 
                    setIsLoading={() => this.setState({isLoading: true})}
                    isLoading={this.state.isLoading}
                    reorder={()=>this.reorder(true)} />
                :
                    
                    programme.docs.map((item, index, arr) => {
                        lastItemInList = this.state.timingsArray[item.id];
                        lastDurationInList = item.data.duration;
                        
                        return (
                            <div key={item.id}>
                                    <ProgrammeItem 
                                        item={item} 
                                        isToday={isToday} 
                                        itemTime={moment(item.data.itemTime)} 
                                        currentTab={this.state.currentTab}
                                        deleteItem={() => this.deleteItem(item)}
                                        editItem={() => this.editItem(toJS(item.data), this.state.timingsArray[item.id],item)}
                                        logTimeNow={() => this.logTimeNow(item, toJS(item.data))}
                                        addNewItem={(itemTime, duration, orderCount) => this.addNewItem(itemTime, duration, orderCount)}
                                    ></ProgrammeItem>
                    
                                    {(arr.length - 1 === index) ? // last item
                                    <div style={{textAlign: 'center', fontSize: '20px', marginTop: 16, color: blue['600'], fontWeight: '600'}}>
                                        End Time: {moment(item.data.itemTime).add(moment.duration(parseInt(item.data.duration), 'minutes')).format('LT')}
                                    </div>
                                    :''}
                            </div>
                        )
                    })}
                </div>


                {this.state.showAddEditItem}


                {(this.state.currentTab === "Edit") ?
                    <Snackbar
                    open={true}
                    message="Editing: Reorder is now in Reorder Tab"
                    action={<Button onClick={() => this.changeTab(null, "View")} color="secondary" size="small">DONE</Button>}
                    onClose={(reason) => {if (reason === 'clickaway') {} }}
                    style={{}} />
                :''}

                {(this.state.currentTab === "Reorder") ?
                    <Snackbar
                    open={true}
                    message="Reorder: Drag and drop to reorder"
                    action={<Button onClick={() => this.changeTab(null, "View")} color="secondary" size="small">DONE</Button>}
                    onClose={(reason) => {if (reason === 'clickaway') {} }}
                    style={{}} />
                :''}    
                
                {this.state.thePopup}

                {(this.state.isLoading) ?
                    <Dialog open={true} style={{padding: 30}}>
                        <DialogContent>
                            <CircularProgress />
                        </DialogContent>
                    </Dialog>
                :''}

                {(this.state.currentTab === "Ops") ?
                    <div style={{position: 'fixed', bottom: '56px', width: '100%', height: '40px', padding: '16px', background: '#000', color: '#fff', textAlign: 'center', fontWeight: '600', fontSize: '2rem', zIndex: '555'}}
                    >
                        <Clock
                            format={'h:mm:ssa'}
                            ticking={true} />
                    </div>
                    
                :''}

                {(this.state.showLoginPrompt) ?
                <div style={{ position: 'fixed', top: '56px', left: '0', right: '0', width: '100%', background: '#fff', zIndex: '999'}}>
                    <div style={{padding: '16px'}}>
                        Log in to save this runsheet in your list.
                        <Button fullWidth color="primary" variant="contained" style={{marginTop: 16, color: '#fff'}}
                            href="/login">
                            Log in
                        </Button>
                        <Button fullWidth color="primary" variant="outlined" style={{marginTop: 8}}
                            onClick={() => this.setState({showLoginPrompt: false})}>
                            Skip
                        </Button>
                    </div>
                </div>
                
                :''}

            </div>
        )
    }
});

export default Programme;
