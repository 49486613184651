import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment';
import htmlToText from 'html-to-text';

// UI Components
import {List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {SharingDialog} from './programme/SharingDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

// Firebase Store
import { observer } from 'mobx-react';
import * as FirebaseStore from "../firebase/store";
import firebaseApp from "../firebase/store";
//import {blue400, indigo500} from 'material-ui/styles/colors';
// import blue from '@material-ui/core/colors/blue';
// import indigo from '@material-ui/core/colors/indigo';

var ReactGA = require('react-ga');
ReactGA.initialize('UA-101242277-1');
moment().format();


const runsheet = FirebaseStore.store.runsheet;
const programme = FirebaseStore.store.programme;
const people = FirebaseStore.store.people;
const songs = FirebaseStore.store.songs;
const users = FirebaseStore.store.users;
const currentUserInRunsheet = FirebaseStore.store.currentUserInRunsheet;
const db = firebaseApp.firestore();

// https://gist.github.com/rproenca/64781c6a1329b48a455b645d361a9aa3
window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);

class UserListItem extends Component {
    constructor(props){
        super(props);

        this.state = {
            role: this.props.role,
            clipboard: ""
        };

        this.handleRoleChange = this.handleRoleChange.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    handleRoleChange(e) {
        var _self = this;
        //this.setState({role: e.target.value});
        FirebaseStore.addRunsheetToUser(runsheet.id, _self.props.userId, e.target.value);
        users.query = users.ref.orderBy('role', 'asc');
    }

    deleteUser(e){
        var _self = this;
        FirebaseStore.removeRunsheetFromUser(runsheet.id, _self.props.userId);
        users.query = users.ref.orderBy('role', 'asc');
    }

    componentDidMount() {
        programme.query = programme.ref.orderBy('orderCount', 'asc');
    }

    render(){
        return (
            <div style={{paddingLeft: 16, paddingRight: 16}}>
                <Grid spacing={3} container>
                    <Grid item xs={1}>
                        <Icon onClick={this.deleteUser}>close</Icon>
                    </Grid>
                    <Grid item xs={7}>
                        <Typography variant="body2">
                            {this.props.userId}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {(FirebaseStore.getUserId() === this.props.userId) ? 
                            <Typography variant="body2">(You)</Typography>
                            :
                            <select
                                className="roleDropdown"
                                value={this.props.role}
                                onChange={this.handleRoleChange}
                                style={{width: '100%', height: '32px', background: 'white', fontSize: '14px', border: '1px solid rgba(0,0,0,0.15)'}}
                            >
                                <option value={"viewer"}>Viewer</option>
                                <option value={"editor"}>Editor</option>
                            </select>
                        }
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const Sharing = observer(class Sharing extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userId: "",
            openAlert: false,
            timingsArray: [],
            snackbarOpen: false,
            message: null,
            showSharingDialog: null,
            isLoading: false
        };

        this.validator = new SimpleReactValidator();
        this.updateUserId = this.updateUserId.bind(this);
        this.addUser = this.addUser.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.sendWhatsapp = this.sendWhatsapp.bind(this);
        this.sendToClipboard = this.sendToClipboard.bind(this);
        this.copyProgramme = this.copyProgramme.bind(this);
        this.copyReport = this.copyReport.bind(this);
        this.copyLink = this.copyLink.bind(this);
        this.generatePlainTextReportAll = this.generatePlainTextReportAll.bind(this);
        this.generatePlainTextReportTitles = this.generatePlainTextReportTitles.bind(this);
        this.generatePlainTextReportStartEnd = this.generatePlainTextReportStartEnd.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShare = this.handleShare.bind(this);
    }

    addUser() {
        var _self = this;
        // check if email is valid first
        if (this.validator.allValid()) {
            FirebaseStore.addRunsheetToUser(runsheet.id, _self.state.userId, "viewer");
            this.setState({userId: ""});
            this.validator.hideMessages();
            users.query = users.ref.orderBy('role', 'asc');
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
        }
    }

    updateUserId(e) {
        this.setState({userId: e.target.value.toLowerCase()});
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.addUser();
        }
    }

    generatePlainTextReportAll = async () => {
        await runsheet.fetch();
        let runsheetName = runsheet.data.name;
        let runsheetDate = runsheet.data.date;
        programme.query = programme.ref.orderBy('orderCount', 'asc');
        await programme.ready();
        let docs = programme.docs;
        var composeMessage = "";

        composeMessage += "*" + runsheetName + "*\n";
        composeMessage += moment(runsheetDate).format("DD-MM-YYYY") + "\n\n";
        composeMessage += encodeURI("http://runsheetpro.com/services/" + this.props.serviceKey + "/" + runsheetName + "/Programme") + "\n\n";

        docs.map((doc, index) => {
            var item = doc.data;

            if (item.duration !== null){
                var theTime = moment(item.itemTime);

                // get duration
                var printDuration = "(" + item.duration + " min)";
                // print time
                composeMessage +=  "*" + theTime.format("h:mm a") + ":* ";
            } else {
                composeMessage += "      ";
            }

            if (item.text !== null){
                composeMessage += item.text + "\n";
            }
            if (item.remarks !== null && item.remarks !== ""){
                composeMessage += htmlToText.fromString(item.remarks);
            }
            composeMessage += printDuration + "\n\n";
        });
        return composeMessage;
    }

    generatePlainTextReportStartEnd = async () => {
        await runsheet.fetch();
        let runsheetName = runsheet.data.name;
        let runsheetDate = runsheet.data.date;
        programme.query = programme.ref.orderBy('orderCount', 'asc');
        await programme.ready();
        let docs = programme.docs;
        var composeMessage = "";

        composeMessage += "*" + runsheetName + "*\n";
        composeMessage += moment(runsheetDate).format("DD-MM-YYYY") + "\n\n";

        docs.map((doc, index) => {
            var item = doc.data;

            if (item.duration !== null){
                var theTime = moment(item.itemTime);

                // get duration
                var printDuration = "(" + item.duration + " min)";
                if(item.oldDuration){
                    printDuration += " (was " + item.oldDuration + " min)"
                }

                // print time
                composeMessage +=  "*" + theTime.format("h:mm a") + "-";

                // print end time
                let endTime = theTime.add(item.duration, 'minutes');
                composeMessage +=  endTime.format("h:mm a") + "* ";

                // print duration
                composeMessage += printDuration + " ";

                // print text
                if (item.text !== null){
                    composeMessage += item.text + "\n";
                }
                
            } else {
                composeMessage += "      ";
            }
        });

        composeMessage += "\n" + encodeURI("http://runsheetpro.com/services/" + this.props.serviceKey + "/" + runsheetName + "/Programme") + "\n\n";

        return composeMessage;
    }

    generatePlainTextReportTitles = async () => {
        await runsheet.fetch();
        let runsheetName = runsheet.data.name;
        let runsheetDate = runsheet.data.date;
        programme.query = programme.ref.orderBy('orderCount', 'asc');
        await programme.ready();
        let docs = programme.docs;
        var composeMessage = "";

        composeMessage += "*" + runsheetName + "*\n";
        composeMessage += moment(runsheetDate).format("DD-MM-YYYY") + "\n\n";

        docs.map((doc, index) => {
            var item = doc.data;

            if (item.duration !== null){
                var theTime = moment(item.itemTime);

                // get duration
                var printDuration = "(" + item.duration + " min)";
                if(item.oldDuration){
                    printDuration += " (was " + item.oldDuration + " min)"
                }

                // print time
                composeMessage +=  "*" + theTime.format("h:mm a") + "* ";
                if (item.text !== null){
                    composeMessage += item.text + "\n";
                }
            composeMessage += printDuration + "\n";
                
            } else {
                composeMessage += "      ";
            }
        });

        composeMessage += "\n" + encodeURI("http://runsheetpro.com/services/" + this.props.serviceKey + "/" + runsheetName + "/Programme") + "\n\n";

        return composeMessage;
    }

    copyLink = async () => {
        await runsheet.fetch();
        let runsheetName = runsheet.data.name;
        var link = encodeURI("http://runsheetpro.com/services/" + this.props.serviceKey + "/" + runsheetName + "/Programme");
        this.sendToClipboard(link);
    }

    sendWhatsappLink = async () => {
        await runsheet.fetch();
        let runsheetName = runsheet.data.name;
        var link = encodeURI("http://runsheetpro.com/services/" + this.props.serviceKey + "/" + runsheetName + "/Programme");

        var composeMessage = runsheetName + " " + link;
        composeMessage=encodeURIComponent(composeMessage);
        // console.log(composeMessage);

        ReactGA.event({
            category: 'Share',
            action: 'Share Whatsapp',
            label: 'Programme'
        });

        window.location = "whatsapp://send?text=" + composeMessage;
        this.setState({isLoading: false});
    }

    copyProgramme = async () => {
        this.setState({isLoading: true});
        var message = await this.generatePlainTextReportAll(); 
        this.sendToClipboard(message);
    }

    sendWhatsapp = async () => {
        var composeMessage = await this.generatePlainTextReportAll();
        composeMessage=encodeURIComponent(composeMessage);
        // console.log(composeMessage);

        ReactGA.event({
            category: 'Share',
            action: 'Share Whatsapp',
            label: 'Programme'
        });

        window.location = "whatsapp://send?text=" + composeMessage;
        this.setState({isLoading: false});
    }

    copyReport = async () => {
        this.setState({isLoading: true});
        var message = await this.generatePlainTextReportTitles(); 
        this.sendToClipboard(message);
    }

    copyStartEndReport = async () => {
        this.setState({isLoading: true});
        var message = await this.generatePlainTextReportStartEnd(); 
        this.sendToClipboard(message);
    }

    sendWhatsappReport = async () => {
        var composeMessage = await this.generatePlainTextTitles();
        composeMessage=encodeURIComponent(composeMessage);
        // console.log(composeMessage);

        ReactGA.event({
            category: 'Share',
            action: 'Share Whatsapp Report',
            label: 'Programme'
        });

        window.location = "whatsapp://send?text=" + composeMessage;
        this.setState({isLoading: false});
    }

    sendWhatsappStartEndReport = async () => {
        var composeMessage = await this.generatePlainTextReportStartEnd();
        composeMessage=encodeURIComponent(composeMessage);
        // console.log(composeMessage);

        ReactGA.event({
            category: 'Share',
            action: 'Share Whatsapp Report',
            label: 'Programme'
        });

        window.location = "whatsapp://send?text=" + composeMessage;
        this.setState({isLoading: false});
    }


    sendToClipboard = (message) => {
        window.Clipboard.copy(message);
        this.setState({snackbarOpen: true, message: message, isLoading: false});
    }

    componentWillMount() {
        const id = this.props.serviceKey;
        programme.path = 'runsheets/' + id + '/programme';
        people.path = 'runsheets/' + id + '/people';
        songs.path = 'runsheets/' + id + '/songs';
        users.path = 'runsheets/' + id + '/users';
        runsheet.path = 'runsheets/' + id;
        currentUserInRunsheet.path = 'runsheets/' + id + '/users/' + FirebaseStore.getUserId();
        users.query = users.ref.orderBy('role', 'asc');
    }

    handleClose() {
        this.setState({openAlert: false});
    }

    askSharingFormat(type) {
        let theSharingDialog = 
            <SharingDialog
            open={true}
            onClose={(format) => this.handleShare(format, type)}
            value={"startendtimes"}
            />

        this.setState({showSharingDialog: theSharingDialog});
    }

    handleShare(format, type){
        this.setState({isLoading: true});
        if(type === "text"){
            if(format === "all"){
                this.copyProgramme();
            } else if (format === "titles"){
                this.copyReport();
            } else if (format === "startendtimes"){
                this.copyStartEndReport();
            } else {
                this.setState({isLoading: false, showSharingDialog: null});
            }
        } else if (type === "whatsapp") {
            if(format === "all"){
                this.sendWhatsapp();
            } else if (format === "titles"){
                this.sendWhatsappReport();
            } else if (format === "startendtimes"){
                this.sendWhatsappStartEndReport();
            } else {
                this.setState({isLoading: false, showSharingDialog: null});
            }
        } 
        this.setState({showSharingDialog: null});
    }

    render() {
        // check if user is admin
        var isAdmin = false; // @TODO Change back later
        if(currentUserInRunsheet.data.role === "editor") {
            isAdmin = true;
        }

        // var forceLoad = runsheet.data;
        // var forceLoad2 = programme.docs.map((doc) => <div></div>);

        // const actions = [
        //     <Button
        //       label="OK"
        //       primary={true}
        //       keyboardFocused={true}
        //       onClick={this.handleClose}
        //     />
        // ];
        
        return (
            <div style={{marginBottom: '56px', marginTop: '56px'}}>
                <List>
                    <ListSubheader color="primary">GET SHAREABLE LINK</ListSubheader>
                    <ListItem button
                        onClick={() => this.copyLink()}>
                        <ListItemIcon>
                            <Icon className="material-icons" color="primary">link</Icon>
                        </ListItemIcon>
                        <ListItemText 
                        primary="Copy Link to Clipboard" />
                    </ListItem>
                    <Divider />
                    <ListItem  button
                        onClick={() => this.sendWhatsappLink()}>
                        <ListItemIcon>
                            <Icon className="material-icons" color="primary">message</Icon>
                        </ListItemIcon>
                        <ListItemText 
                        primary="Share Link on Whatsapp" />
                    </ListItem>
                    <Divider style={{marginBottom: 16}}/>

                    <ListSubheader color="primary">COPY RUNSHEET / TIMINGS AS PLAIN TEXT</ListSubheader>
                    <ListItem button
                        onClick={() => this.askSharingFormat("text")}>
                        <ListItemIcon>
                            <Icon className="material-icons" color="primary">content_copy</Icon>
                        </ListItemIcon>
                        <ListItemText 
                        primary="Copy Text to Clipboard" 
                        secondary="" />
                    </ListItem>
                    <Divider />

                    <ListItem  button
                        onClick={() => this.askSharingFormat("whatsapp")}>
                        <ListItemIcon>
                            <Icon className="material-icons" color="primary">message</Icon>
                        </ListItemIcon>
                        <ListItemText 
                        primary="Share Text on Whatsapp" />
                    </ListItem>
                    <Divider style={{marginBottom: 16}}/>
                    

                    {(isAdmin) ?
                        <div>
                            <ListSubheader color="primary">ADD USER</ListSubheader>
                            <div style={{padding: '0 16px 16px 16px'}}>
                                <Grid spacing={2} container>
                                    <Grid item xs={9}>
                                        <TextField 
                                        fullWidth
                                        style={{marginRight: '16px'}} 
                                        label="Email Address" 
                                        value={this.state.userId} 
                                        onChange={this.updateUserId} 
                                        onKeyPress={this.handleKeyPress} />
                                        <small style={{color: 'red'}}>{this.validator.message('email', this.state.userId, 'required|email')}</small>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button style={{marginTop: 16}} variant="contained" color="primary" onClick={this.addUser}>Add</Button>
                                    </Grid>
                                </Grid>
                                <br/>

                                <Typography variant="caption">
                                    After adding a user, they can login to runsheetpro.com and see the runsheet you added.
                                    Users who are not added will still be able to access the runsheet via the unique link.
                                </Typography>
                            </div>
                            <Divider />
                            <ListSubheader color="primary">SHARED WITH</ListSubheader>
                            {users.docs.map((user, index) => {
                                return (
                                    <UserListItem userId={user.id} role={user.data.role} key={index} />
                                )
                            })}
                        </div>
                    : 
                    ''}
                </List>
                <Dialog open={this.state.snackbarOpen} onClose={()=>{this.setState({snackbarOpen: false})}}>
                    <DialogTitle>
                    Text successfully copied to clipboard!
                    </DialogTitle>
                    <DialogContent>
                        <textarea defaultValue={this.state.message} style={{height: 'auto', minHeight: '100px', width: '100%'}}>   
                        </textarea>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{this.setState({snackbarOpen: false})}} color="primary">
                        Close
                    </Button>
                    </DialogActions>
                </Dialog>

                {this.state.showSharingDialog}

                {(this.state.isLoading) ?
                    <Dialog open={true} style={{padding: 30}}>
                        <DialogContent>
                            <CircularProgress />
                        </DialogContent>
                    </Dialog>
                :''}
            </div>
        )
    }
});

export default Sharing;
