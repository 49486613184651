import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import {TimePicker} from '@material-ui/pickers';

const trunc = (string, n) => {
    return (string.length > n) ? string.substr(0, n-1) + '...' : string;
}

export const LogTimeNow = (props) => {
    const [endTime, setEndtime] = useState(moment());

    const handleTimeChange = (time) => {
        setEndtime(time);
    }

    return (
        <Dialog open={true}
            onClose={props.onClose}
        >
        <DialogTitle>
            Confirm End Time
        </DialogTitle>
        <DialogContent>
        <div style={{marginTop: 16, marginBottom: 32}}>
            <strong>{trunc(props.data.text, 20)}</strong> has ended at: 
            <TimePicker
                fullWidth
                value={endTime}
                onChange={handleTimeChange}
            />
            <br/><br/>
            All subsequent timings will be updated accordingly.
        </div>
        </DialogContent>
        <DialogActions>
        <Button onClick={props.onClose} color="primary">
            Cancel
        </Button>
            <Button onClick={() => props.onSubmit(endTime)} color="primary" variant="contained" autoFocus>
            Log time now
        </Button>
        </DialogActions>
    </Dialog>
    )
}
