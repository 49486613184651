import firebase from '@firebase/app';
import '@firebase/firestore';
import { initFirestorter, Collection, Document } from 'firestorter';
import { observable } from 'mobx';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAm3wY5fF1qkQBYeR4DYzFhYzc-TuSynYU",
    authDomain: "runsheetpro.com",
    databaseURL: "https://servicerunsheet.firebaseio.com",
    storageBucket: "servicerunsheet.appspot.com",
    projectId: "servicerunsheet",
    messagingSenderId: "442170353088"
});
export default firebaseApp;

const db = firebase.firestore();
// db.settings({ timestampsInSnapshots: true });

initFirestorter({ firebase: firebase });

// API to add doc to a collection
export const addDocToCollection = async (collection, data) => {
    try {
        await collection.add(data).then(function (doc) {
            return doc;
        });
    }
    catch (err) {
        console.log(err);
    }
}

// API to delete doc from collection
export const deleteDoc = async (doc) => {
    try {
        await doc.delete();    
    }
    catch (err) {
        console.log(err);
    }
};

export const getUserId = () => {
    var userId = null;
    var user = firebase.auth().currentUser;
    if (user) {
        // User is signed in.
        userId = user.email;
    } else {
        // No user is signed in.
        console.log('No user signed in');
    }
    return userId;
}

export async function getUserIdAsync(){
    return new Promise((resolve, reject) => {
        let user = firebase.auth().currentUser;
        return user ? resolve(user.email) : reject(console.log('No user signed in')); 
    })
}

// Add runsheet to a user
export const addRunsheetToUser = (id, userId, role) => {
    if (role === undefined){
        role = "viewer";
    }
    // add this runsheet ID to the user's runsheets collection
    db.collection('users/' + userId + '/runsheets').doc(id).set({
        id: id,
    });
    // add this user ID to the runsheet's users collection
    db.collection('runsheets/' + id + '/users').doc(userId).set({
        id: userId.toLowerCase(),
        role: role
    });
}

// Remove runsheet from a user
export const removeRunsheetFromUser = (id, userId) => {
    db.collection('users/' + userId + '/runsheets').doc(id).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });

    db.collection('runsheets/' + id + '/users').doc(userId).delete().then(function() {
        console.log("Document successfully deleted!");
    }).catch(function(error) {
        console.error("Error removing document: ", error);
    });
}


export const addNewUserToDoc = (runsheet) => {
    var userId;
    let _self = this; 
    
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            // User is signed in.
            userId = user.email;

            var userDoc = db.collection("runsheets/" + runsheet.id + "/users/").doc(userId);
            var getDoc = userDoc.get()
            .then(doc => {
                if (!doc.exists) {
                    // No such user - add to runsheet
                    addRunsheetToUser(runsheet.id, userId, "viewer");
                }
            })
            .catch(err => {
                console.log('Error getting document', err);
            });
        } else {
            // No user is signed in.
            // do nothing
        }
    });  
}


export const store = {
    runsheets: new Collection('runsheets'),     // collection of runsheets
    runsheet: new Document(),                   // 1 runsheet
    programme: new Collection('programme', { // permanently enable real-time updating
        mode: 'on'
        // query: (ref) => ref.orderBy('orderCount', 'asc')
    }),                // programme in the runsheet
    people: new Collection(),                   // people in the runsheet
    songs: new Collection(),                   // people in the runsheet
    users: new Collection(),          // users in the runsheet
    allUsers: new Collection('users','on'),
    currentUser: new Document(),
    currentUserInRunsheet: new Document(),
    runsheetsByUser: new Collection(),     // collection of runsheets
    notes: new Document(),
    timingsArray: []
};

// https://www.robinwieruch.de/react-firebase-mobx-tutorial/
