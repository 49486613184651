import React from 'react';
import MediaQuery from 'react-responsive';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Splash from './components/Splash.jsx';
import Runsheets from "./components/Runsheets.jsx";
import Programme from './components/Programme.jsx';
import People from './components/People.jsx';
import Songs from './components/Songs.jsx';
import Sharing from './components/Sharing.jsx';
import BottomNav from './components/BottomNav.jsx';
import Login from './auth/Login.js';
import Logout from './auth/Logout.js';
import { firebaseAuth } from './firebase/Firebase';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import './App.css';
import {
  Router,
  Route,
  Link,
  Redirect
} from 'react-router-dom';
import history from "./history";

// Firestore things
import { observer } from 'mobx-react';
import * as FirebaseStore from "./firebase/store";
import firebaseApp from './firebase/store';
const currentUser = FirebaseStore.store.currentUser;
const currentUserInRunsheet = FirebaseStore.store.currentUserInRunsheet;

var db = firebaseApp.firestore();


var ReactGA = require('react-ga');
ReactGA.initialize('UA-101242277-1');


const track = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
}
class TrackPageView extends React.Component {
    componentWillMount() { track() }
    componentWillUpdate() { track() }
    render() { return <Route children={this.props.children}/> }
}

// const AppBarStyle = {
//     position: 'fixed',
//     background: indigo[500]
// }

const Home = () => (
     <Splash />
)


const ProgrammeTab = ({ match }) => (
    <Programme serviceKey={`${match.params.id}`} />
)

const PeopleTab = ({ match }) => (
    <People serviceKey={`${match.params.id}`} />
)

const SonglistTab = ({ match }) => (
    <Songs serviceKey={`${match.params.id}`} />
)

const SharingTab = ({ match }) => (
    <Sharing serviceKey={`${match.params.id}`} />
)

// then our route config
const routes = [
    { path: '/services/:id/:name/Programme',
        component: ProgrammeTab,
        uid: 'this.state.uid',
        userId: 'this.state.userId'
    },
    { path: '/services/:id/:name/Notes',
        component: PeopleTab,
        uid: 'this.state.uid',
        userId: 'this.state.userId'
    },
    { path: '/services/:id/:name/Songs',
        component: SonglistTab,
        uid: 'this.state.uid',
        userId: 'this.state.userId'
    },
    { path: '/services/:id/:name/Share',
        component: SharingTab,
        uid: 'this.state.uid',
        userId: 'this.state.userId'
    }
]

const Service = ({ match, location }) => {
    return (

        <div className="Service">
            {routes.map((route, i) => (
                <Route exact 
                history={history} 
                key={i} 
                path={route.path}
                component={route.component} {...route}/>
            ))}
            <MediaQuery maxWidth={1023}>
                <BottomNav serviceKey={match.url} currLocation={location.pathname} />
            </MediaQuery>
            <MediaQuery minWidth={1024}>
                <BottomNav serviceKey={match.url} isDesktop={true} currLocation={location.pathname}/>
            </MediaQuery>
        </div>

    )
}

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
const RouteWithSubRoutes = (route) => (
    <Route history={history} path={route.path} render={props => (
            // pass the sub-routes down to keep nesting
            <route.component {...props} routes={route.routes}/>
        )}/>
)

// function PrivateRoute ({component: Component, authed, ...rest}) {
//   return (
//     <Route
//       {...rest}
//       render={(props) => authed === true
//         ? <Component {...props} />
//         : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
//     />
//   )
// }

const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PrivateRoute = ({ component, authed, history, ...rest }) => {
  return (
    <Route history={history} {...rest} render={routeProps => {
      return authed === true
      ? (
        renderMergedProps(component, routeProps, rest)
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: routeProps.location }
        }}/>
      );
    }}/>
  );
};

class LogoutMenu extends React.Component {
    state = {
      anchorEl: null,
    };
  
    handleClick = event => {
      this.setState({ anchorEl: event.currentTarget });
    };
  
    handleClose = () => {
      this.setState({ anchorEl: null });
    };
  
    render() {
        const { anchorEl } = this.state;

        return (
            <div>
                <IconButton
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={this.handleClick}
                color="inherit"
                >
                    <Icon color="inherit">more_vert</Icon>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                <MenuItem><Link to='/logout'>Log Out</Link></MenuItem>
                </Menu>
            </div>
        )
    }
}

const App = observer(class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authed: false,
            loading: true,
            page: null,
            title: null,
            currentServiceKey: null,
            uid: null,
            userId: null
        };
        this.changePage = this.changePage.bind(this);
    }


    changePage = (index) => {
        if (index === 1){
            this.setState({
                // page: <TabView serviceKey={this.state.currentServiceKey} />,
                title: "View RunsheetPro",
                currentServiceKey: null
            });
        }
        if (index === 0){
            this.setState({
                // page: <Select goToService={(key) => this.goToService(key)} />,
                title: "RunsheetPro (v2)",
                currentServiceKey: null
            });
        }
    }

    handleBackToHome = () => {
        this.changePage(0);
    }

    componentDidMount() {
        // sets default page here
        this.changePage(0);

        let _self = this;

        firebaseAuth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    uid: user.uid,
                    userId: user.email,
                    authed: true,
                    loading: false,
                });
                currentUser.path = "users/" + user.email;
                if(this.state.currentServiceKey !== null){
                    currentUserInRunsheet.path = 'runsheets/' + this.state.currentServiceKey + '/users/' + user.email;        
                }
            } else {
                this.setState({
                    uid: null,
                    userId: null,
                    authed: false,
                    loading: false
                })
            }
        })
    }

    componentWillUnmount () {
        //this.removeListener()
    }

    render(){

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
                <TrackPageView>
                    <div>
                        <Route history={history} exact path="/Runsheets" render={() => <AppBar position="fixed">
                                                                        <Toolbar>
                                                                            <Typography variant="h6" color="inherit" style={{flexGrow: '1'}}>
                                                                            RunsheetPro v3.0
                                                                            </Typography><LogoutMenu></LogoutMenu>
                                                                        </Toolbar>
                                                                    </AppBar>
                                                               } />
                        <Route history={history} exact path="/login" render={() => <AppBar position="static">
                                                                        <Toolbar>
                                                                            <Typography variant="h6" color="inherit">
                                                                            Login
                                                                            </Typography>
                                                                        </Toolbar>
                                                                    </AppBar>}/>
                        <Route history={history} exact path="/logout" render={() => <AppBar position="static">
                                                                        <Toolbar>
                                                                            <Typography variant="h6" color="inherit">
                                                                            Logout
                                                                            </Typography>
                                                                        </Toolbar>
                                                                    </AppBar>}/>                        
                        <Route history={history} path="/services/:id/:name" render={({ match }) => {
                            return (
                                <AppBar position="fixed">
                                    <Toolbar>
                                        <Link to="/Runsheets">
                                            <IconButton color="inherit">
                                                <Icon className="material-icons">arrow_back</Icon>
                                            </IconButton>
                                        </Link>
                                        <Typography variant="h6" color="inherit">
                                        {match.params.name}
                                        </Typography>
                                    </Toolbar>
                                </AppBar> 
                            )}}
                        />
                        <div>
                            <Route history={history} exact path="/" component={Home}/>
                            <PrivateRoute history={history} authed={this.state.authed} path='/Runsheets' component={Runsheets} uid={this.state.uid} />
                            <Route history={history} authed={this.state.authed} path='/services/:id/:name' component={Service} uid={this.state.uid} userId={this.state.userId} />
                            <Route history={history} exact path="/login" component={Login} />
                            <Route history={history} exact path="/logout" component={Logout} />
                        </div>
                    </div>
                </TrackPageView>
            </Router>
            </MuiPickersUtilsProvider>
        );
    }

});

export default App;
